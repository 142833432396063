import React, { Component } from 'react';
import ScrollContext from './ScrollContext';

class ScrollProvider extends Component {
  constructor() {
    super();

    this.cb = [];
    this.windowInnerHeight = 0;

    this.onScroll = this.onScroll.bind(this);
    this.onResize = this.onResize.bind(this);
    this.addScrollCallback = this.addScrollCallback.bind(this);
    this.removeScrollCallback = this.removeScrollCallback.bind(this);
    this.callCb = this.callCb.bind(this);
  }

  componentDidMount() {
    this.scrollingElement = document.scrollingElement || document.documentElement || document.body;

    window.addEventListener('scroll', this.onScroll, true);
    window.addEventListener('resize', this.onResize, true);

    this.onResize();
    this.onScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, true);
    window.removeEventListener('resize', this.onResize, true);
  }

  onResize = () => {
    this.windowInnerHeight = window.innerHeight;
  };

  onScroll = () => {
    requestAnimationFrame(this.callCb);
  };

  callCb() {
    const { scrollTop, scrollHeight } = this.scrollingElement;

    this.cb.forEach(cb =>
      cb({
        scrollTop,
        scrollHeight,
        windowInnerHeight: this.windowInnerHeight
      })
    );
  }

  addScrollCallback(f) {
    this.cb.push(f);
  }

  removeScrollCallback(f) {
    const index = this.cb.findIndex(currentF => f === currentF);
    this.cb.splice(index, 1);
  }

  render() {
    const { children } = this.props;
    return (
      <ScrollContext.Provider
        value={{
          addScrollCallback: this.addScrollCallback,
          removeScrollCallback: this.removeScrollCallback
        }}
      >
        {children}
      </ScrollContext.Provider>
    );
  }
}

export default ScrollProvider;
