import React from 'react';
import Head from '@shared/Head/Head';
import styles from './layout.module.scss';
import ScrollProvider from '../context/ScrollProvider';
import '@styles/global.scss';

const DefaultLayout = props => {
  const { children } = props;
  return (
    <div id="layout" className={styles.layout}>
      <ScrollProvider>
        <Head />
        {children}
      </ScrollProvider>
    </div>
  );
};

export default DefaultLayout;
