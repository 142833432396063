import React from 'react';
import { Helmet } from 'react-helmet';
import favicon from '@static/images/favicon.ico';

const Head = () => {
  return (
    <Helmet>
      <html lang="en" />

      {/* <link
        rel="preload"
        as="font"
        href="/fonts/apercu-mono-regular.woff2"
        type="font/woff2"
        crossOrigin="anonymous"
      /> */}

      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <link rel="icon" type="image/png" href={favicon} />
    </Helmet>
  );
};

export default Head;
